import AppConstants from "appconstants.json";
import Separator from "components/Separator";
import { Vendor, getVendorFromCurrentHost, getVendorFromHost } from "util/host";
import { concat } from "util/string";

const defaultRebrandedCfseLogo = "/pro-marketplace.svg";
const defaultCfseLogo = "/cp_marketplace_logo.png";
const defaultArbysLogo = "/qsarbyscobrand_749.png";
const nobleChemicalLogo = "/noble-chemical-logo.png";
const elevenFourHundredLogo = "/elevenfourhundred-logo.png";
const defaultQsLogo = "/quicksupply.png";
const ndcpLogo = "/ndcp-logo2_2256.jpeg";

export const baseLogoPath = "https://resources.cfse.biz/cna/logo-images";
const fullRebrandedCfseLogoPath = baseLogoPath + defaultRebrandedCfseLogo;
const fullCfseLogoPath = baseLogoPath + defaultCfseLogo;
const fullArbysLogoPath = baseLogoPath + defaultArbysLogo;
const fullNobleChemicalLogoPath = baseLogoPath + nobleChemicalLogo;
const fullElevenFourHundredLogoPath = baseLogoPath + elevenFourHundredLogo;
const fullNdcpLogoPath = baseLogoPath + ndcpLogo;
export const fullQsLogoPath = baseLogoPath + defaultQsLogo;

export function getLogoFromCurrentHost(): string {
  return getLogoFromHost(window.location.hostname);
}

export function getLogoFromHost(windowLocationHost: string): string {
  switch (getVendorFromHost(windowLocationHost)) {
    case "arbys":
      return fullArbysLogoPath;
    case "ndcp":
      return fullNdcpLogoPath;
    case "quicksupply":
      return fullQsLogoPath;
    case "noblechemical":
      return fullNobleChemicalLogoPath;
    case "11400inc":
      return fullElevenFourHundredLogoPath;
    case "pro":
      return AppConstants.enableLogoRebranding
        ? fullRebrandedCfseLogoPath
        : fullCfseLogoPath;
  }
}

export function getLogoSize(vendor: Vendor): { width: string; height: string } {
  switch (vendor) {
    case "arbys":
      return { width: "260", height: "94" };
    case "11400inc":
      return { width: "384", height: "58" };
    case "ndcp":
      return { width: "240", height: "120" };
    case "noblechemical":
      return { width: "300", height: "118" };
    case "pro":
      return AppConstants.enableLogoRebranding
        ? { width: "240", height: "120" }
        : { width: "384", height: "84" };
    case "quicksupply":
      return { width: "160", height: "74" };
  }
}

export default function Logo({
  className,
}: Readonly<{
  className?: string;
  isLoginPageLogo?: boolean;
}>): React.ReactElement {
  const vendor = getVendorFromCurrentHost();
  const logo = getLogoFromCurrentHost();

  return (
    <div>
      <div
        className={concat(
          "flex flex-row gap-2 justify-center items-center align-items-center mx-auto",
          className
        )}
      >
        {vendor === "ndcp" && (
          <>
            <img src={fullQsLogoPath} width="160" height="80" alt="" />
            <Separator className="h-20 border-l border-slate-200" />
          </>
        )}
        {logo && (
          <img
            data-testid="vendor-logo"
            src={logo}
            {...getLogoSize(vendor)}
            alt=""
          />
        )}
      </div>
    </div>
  );
}
