import { useEffect } from "react";
import { RouteProps } from "react-router";
import { getHostFaviconUrl, setFaviconUrl } from "util/favicon";

/**
 *  Insecure content wrapper, which will set the favicon based on the host.
 *
 * @export
 * @param {RouteProps} { children }
 * @return {React.ReactElement}
 */
export default function InsecureContent({
  children,
}: RouteProps): React.ReactElement {
  useEffect(() => setFaviconUrl(getHostFaviconUrl()), []);

  return <>{children}</>;
}
