import { QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import * as Sentry from "@sentry/react";
import SecureContent from "authentication/SecureContent";
import InsecureContent from "authentication/InsecureContent";
import { UserProvider } from "authentication/contexts/user.context";
import { FullWidthLayout } from "layouts/FullWidthLayout";
import PageCrashFallback from "routes/Errors/PageCrashFallback";
import { getQueryClient } from "util/requests/react-query";
import routes from "routes/routes";
import { clearNewRelicSession } from "util/NewRelic/newRelicUtils";
import LoadingSpinner from "components/Spinner/LoadingSpinner";
import ToastProvider from "contexts/toast/ToastProvider";
import useUserStore from "authentication/contexts/useUserStore";
import InspectModeRedirect from "routes/Redirects/InspectModeRedirect";

const queryClient = getQueryClient();

// Wrap react-router-dom in Sentry in production builds for Performance monitoring via BrowserTracing
const SentryRoutes =
  process.env.NODE_ENV === "production"
    ? Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes; // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/

/**
 * Wrap the application routes in react-router and manage User state.
 *  Iterates through the routes defined to render the components in a layout and manage private/public Routes.
 * @export
 * @return {*}  {React.ReactElement}
 */
export default function App(): React.ReactElement {
  const cleanup = (): void => {
    queryClient.clear();
    clearNewRelicSession();
  };
  const user = useUserStore(cleanup);
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={PageCrashFallback}
        onReset={(): void => {
          user.logout();
        }}
      >
        <UserProvider {...user}>
          <ToastProvider>
            <QueryClientProvider client={queryClient}>
              <SentryRoutes>
                {Object.values(routes).map((route) => {
                  const content: React.ReactElement = (
                    <InspectModeRedirect route={route}>
                      <FullWidthLayout>
                        <React.Suspense
                          fallback={
                            <LoadingSpinner
                              wrapperClassName="h-[70vh] flex items-center justify-center"
                              className="w-16 h-16"
                            />
                          }
                        >
                          {route.component}
                        </React.Suspense>
                      </FullWidthLayout>
                    </InspectModeRedirect>
                  );
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        route.insecure ? (
                          <InsecureContent>{content}</InsecureContent>
                        ) : (
                          <SecureContent>{content}</SecureContent>
                        )
                      }
                    />
                  );
                })}
              </SentryRoutes>
            </QueryClientProvider>
          </ToastProvider>
        </UserProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
