import {
  UserClaims,
  UserPermissionType,
} from "authentication/contexts/UserData.types";

export const permissionTypes: {
  reviews: UserPermissionType;
  askAQuestion: UserPermissionType;
  companionItems: UserPermissionType;
  goodBetterBest: UserPermissionType;
  relatedItems: UserPermissionType;
  otherProductsFromThisLine: UserPermissionType;
  specs: UserPermissionType;
  filters: UserPermissionType;
  productComparisonTool: UserPermissionType;
  orderGuides: UserPermissionType;
  projectBuilders: UserPermissionType;
  breadcrumbs: UserPermissionType;
  warehouseStockAvailability: UserPermissionType;
  editOrderGuide: UserPermissionType;
  editProjectBuilder: UserPermissionType;
  manageShippingAddresses: UserPermissionType;
  manageBillingAddresses: UserPermissionType;
  manageAccount: UserPermissionType;
  manageAddresses: UserPermissionType;
  placeOrderForMultipleLocations: UserPermissionType;
  createManageUsers: UserPermissionType;
  allowToggleItemsOnLists: UserPermissionType;
  consolidatedWarehouseStockAvailability: UserPermissionType;
  createManageGroupBudgets: UserPermissionType;
  createManageCompanyBudget: UserPermissionType;
  invoiceEmailsAtCheckout: UserPermissionType;
  displayHsCodesAndCountryOfOrigin: UserPermissionType;
} = {
  reviews: "Reviews",
  askAQuestion: "Ask a Question",
  companionItems: "Companion Items",
  goodBetterBest: "Good, Better, Best",
  relatedItems: "Related Items",
  otherProductsFromThisLine: "Other Products from this Line",
  specs: "Specs",
  filters: "Filters",
  productComparisonTool: "Product Comparison Tool",
  orderGuides: "Order Guides",
  projectBuilders: "Project Builders",
  breadcrumbs: "Breadcrumbs",
  warehouseStockAvailability: "Warehouse Stock Availability",
  editOrderGuide: "Edit Order Guide",
  editProjectBuilder: "Edit Project Builder",
  manageShippingAddresses: "Manage Shipping Addresses",
  manageBillingAddresses: "Manage Billing Addresses",
  manageAccount: "Manage Account",
  manageAddresses: "Manage Addresses",
  placeOrderForMultipleLocations: "Place Order For Multiple Locations",
  createManageUsers: "Create/Manage Users",
  allowToggleItemsOnLists: "Allow Toggle Items on Lists",
  consolidatedWarehouseStockAvailability:
    "Consolidated Warehouse Stock Availability",
  createManageGroupBudgets: "Create/Manage Group Budgets",
  createManageCompanyBudget: "Create/Manage Company Budget",
  invoiceEmailsAtCheckout: "Invoice Emails At Checkout",
  displayHsCodesAndCountryOfOrigin:
    "Display Item HS Codes and Country of Origin in Project Builders",
};

export function hasPermission(
  userClaims: UserClaims | null,
  permission: UserPermissionType
): boolean {
  return userClaims?.userPermissions?.includes(permission) ?? false;
}
