import { baseLogoPath } from "components/Logo";
import { getVendorFromCurrentHost } from "util/host";

const defaultFavicon = "/favicon.ico";
const defaultQsFavicon = "/favicon_2a8b33f6-9031-4f97-bd15-cfca0e09e325.ico";
const defaultElevenFourHundredFavicon =
  "/11400-favicon_515a29b9-697e-4c4d-b073-9b0ebdccf46a.ico";

const fullQsFaviconPath = baseLogoPath + defaultQsFavicon;
const fullElevenFourHundredFaviconPath =
  baseLogoPath + defaultElevenFourHundredFavicon;

export function getHostFaviconUrl(): string {
  switch (getVendorFromCurrentHost()) {
    case "arbys":
    case "ndcp":
    case "quicksupply":
      return fullQsFaviconPath;
    case "11400inc":
      return fullElevenFourHundredFaviconPath;
    case "noblechemical":
    case "pro":
      return defaultFavicon;
  }
}

export function setFaviconUrl(faviconUrl: string): void {
  if (isDefaultFavicon(faviconUrl)) {
    // Forces the favicon to be reloaded. Otherwise
    // the browser will get stuck with the default favicon.
    faviconUrl += "?";
  }

  let link = document.querySelector<HTMLLinkElement>('link[rel="icon"]');

  if (!link) {
    link = document.createElement("link");
    link.id = "favicon";
    link.rel = "icon";
    document.head.appendChild(link);
  }

  link.href = faviconUrl;
}

function isDefaultFavicon(faviconUrl: string): boolean {
  return faviconUrl === defaultFavicon;
}
